import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMargin: 2,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>
          Digital Marketing Company for Business Growth | Mannix Infotech Pvt.
          Ltd.
        </title>
        <meta
          name="description"
          content="Elevate your business growth with Mannix Infotech Pvt. Ltd., a leading digital marketing company in Ahmedabad. We provide tailored strategies for effective online presence and success.
"
        />
        <meta
          name="keywords"
          content="professional web design and development company in ahmedabad | top 10 digital makrketing company in Ahmedabad, India | list of best web development company in india | top 10 web design companies in the world | static website development company in Ahmedabad | best custom website design company in ahmedabad | website design and development seo company in ahmedabad
"
        />
        <link rel="canonical" href="https://www.mannix.in/about-us" />
      </Helmet>
      <div>
        <div className="d-flex justify-content-center">
          <div className="row m-4">
            <div className="col-12 col-md-6 p-3 mb-5">
              <div
                className="img-box-container"
                style={{
                  height: "auto",
                  position: "relative",
                  padding: "2px",
                  background: "linear-gradient(to right, #db150e, #f6e58d)",
                  width: "80%",
                }}
              >
                <img
                  src="./aboutCEO.png"
                  className="img-fluid"
                  alt="aboutCEO"
                  style={{
                    position: "relative",
                    maxWidth: "100%",
                    height: "auto",
                    display: "block",
                  }}
                />
                <div
                  className="label d-none d-md-block"
                  style={{
                    position: "absolute",
                    bottom: "-50px",
                    right: "10px",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    width: "250px",
                    height: "100px",
                  }}
                >
                  <h4>
                    <b>MS. MANSI JAIN</b>
                  </h4>
                  <p>
                    <b>DIRECTOR</b>
                  </p>
                  <a
                    href="https://www.linkedin.com/in/mansi-jain-a889b4185?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <i
                        className="fa-brands fa-linkedin fs-2 "
                        style={{
                          position: "relative",
                          left: "200px",
                          top: "-30px",
                        }}
                      ></i>
                    </span>
                  </a>
                </div>
                <div
                  className="box d-md-none d-sm-block"
                  style={{
                    position: "absolute",
                    top: "300px",
                    left: "100px",
                    width: "200px",
                    height: "100px",
                    backgroundColor: "#ffff",
                    border: "0px",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <h4 className="fs-5">
                    <b>MS. MANSI JAIN</b>
                  </h4>
                  <p>
                    <b>DIRECTOR</b>
                  </p>
                  <a
                    href="https://www.linkedin.com/in/mansi-jain-a889b4185?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    style={{
                      position: "relative",
                      left: "150px",
                      top: "-30px",
                    }}
                    className=" "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <i className="fa-brands fa-linkedin fs-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-md-3">
              <div className="fs-5 mt-5" style={{ overflow: "hidden" }}>
                <p className="fw-bold fs-2" style={{ color: "#2980b9" }}>
                  About Mannix Infotech Solutions Pvt.Ltd.
                </p>
                <p>
                  Our mission is to create and execute data-driven solutions
                  that empower our clients' businesses and improve their lives
                  by means of cutting-edge enterprise solutions. We are a
                  worldwide supplier of top-notch software development services.
                  Our team's creativity, expertise, and experience are deeply
                  rooted, and we nurture them to ensure that every project
                  produces measurable outcomes.
                </p>
                <p>
                  founded in Ahmedabad with the straightforward goal of offering
                  all digital solutions in one location. Our goal is to help you
                  stay afloat in changing markets by offering the greatest
                  mobile app development, enterprise mobility, software
                  development, digital marketing, and phone gap development
                  services available. We work together with managers, quality
                  analysts, designers, strategists, and senior technical
                  developers to integrate a variety of contributing elements
                  with the methods and solutions that help businesses take new
                  turns and achieve faster success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5">
          <div className="row mx-0 d-flex justify-content-center">
            <div className="col-12  col-md-6 text-center p-5 left">
              <h2 className="textweb">History</h2>
              <h3>We are here to IT Solutions with 1+ years of experience</h3>

              <div className="menu d-flex justify-content-center">
                <ul className="tab-list">
                  <li>
                    <button
                      style={{ border: "none", borderRadius: "5px" }}
                      type="button"
                      className={`tab-a ${
                        activeTab === "tab1" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab1")}
                    >
                      <button
                        className="d-flex"
                        style={{ background: "none", border: "none" }}
                      >
                        <img src="../vision.png" alt="vision" width="30px" />
                        <p className="px-3 fs-4">Vision</p>
                      </button>
                    </button>
                  </li>
                  <li>
                    <button
                      style={{ border: "none", borderRadius: "5px" }}
                      type="button"
                      className={`tab-a ${
                        activeTab === "tab2" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab2")}
                    >
                      <button
                        className="d-flex"
                        style={{ background: "none", border: "none" }}
                      >
                        <img src="../mission.png" alt="mission" width="30px" />
                        <p className="px-2 fs-4"> Mission</p>
                      </button>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div id="tab1">
                    <p>
                      {" "}
                      Our goal is to maintain our current position in the market
                      while becoming a preeminent provider of web solutions in
                      the IT industry. We promise to assist our clients in
                      accomplishing their business objectives since we recognize
                      that their success is our success. We value precise and
                      superior work.
                    </p>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div id="tab2">
                    <p>
                      Mannix Infotech Solutions Pvt. Ltd. mission is to apply
                      Branding, Digital Development, and Design capabilities to
                      entitle Startups and Brands to meet our client needs. Go
                      above and beyond software to deliver the best Web
                      solutions that convert data into knowledge and help
                      clients solve their problems. This will surpass their
                      expectations.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 right">
              <img src="./vector.jpg" className="img-fluid" alt="vector" />
            </div>
          </div>
        </div>
        <div
          className="container slider-container pt-5 mb-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-center p-2">
            <h2 className="fs-2">
              <b>OUR PROJECTS</b>
            </h2>
            <h4 className="pt-3">
              Mannix Infotech, a top web development company, is ready to take
              your business on the journey of success.
            </h4>
          </div>
          <Slider {...settings} className="px-5 mt-5">
            <div className="slider-item ">
              <img
                src="../Images/minaxipalace.png"
                className="img-fluid"
                alt="minaxipalacelogo"
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Minaxi Palace Hotel</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/balaji.png"
                className="img-fluid"
                alt="balajilogo"
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Balaji Manufacturing Company</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/nyshahypermart.png"
                className="img-fluid"
                alt="nyshahypermartlogo "
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>The Naisha Hypermart</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/balajimobilecare.png"
                className="img-fluid"
                alt="balajimobilecarelogo"
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Balaji Mobile Care</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/mahaveer.png"
                className="img-fluid"
                alt="mahaveerlogo"
              />
              <div className="">
                <p
                  className=" bottom-text "
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Mahaveer Lights</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/arokeenterprises.png"
                className="img-fluid"
                alt="arokeenterprises"
              />
              <div className="">
                <p
                  className=" bottom-text "
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Aroke Enterprise</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/anjalimetal.png"
                className="img-fluid"
                alt="anjalimetal"
              />
              <div className="">
                <p
                  className=" bottom-text "
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Anjali Metals</b>
                </p>
              </div>
            </div>

            <div className="slider-item">
              <img
                src="../Images/mannixgamingearbud.png"
                className="img-fluid"
                alt="mannixgamingearbud"
              />
              <div className="">
                <p
                  className=" bottom-text "
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Mannix Gaming Earbuds</b>
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="d-flex justify-content-center mt-6  ">
          <a href="/portfolio">
            <button
              className="p-3 px-5 mt-4 border-0  text-white"
              style={{
                backgroundColor: "#e84118",
                borderRadius: "7px",
                letterSpacing: "1px",
              }}
            >
              VIEW ALL PROJECTS
            </button>
          </a>
        </div>
        <section className="section2 mt-5 ">
          <div className="container">
            <div className="ops_aboutUs_process_content">
              <div className="ops_aboutUs_process_top">
                <span className="fs-3">What is Our</span>
                <h2>Development Process</h2>
              </div>
              <ul>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_text">
                      <span>1</span>
                      <div>
                        <h2>Research</h2>
                        <p>
                          To analyze the requirement through in-depth research
                          with the help of brilliant tools
                        </p>
                      </div>
                    </div>
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        src="..//reserch.png"
                        height="50"
                        width="50"
                        alt="reserch"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../deliver.png"
                        alt="deliver"
                      />
                    </div>
                    <div className="ops_aboutUs_process_text">
                      <span>6</span>
                      <div>
                        <h2>Deliver</h2>
                        <p>
                          We make delivery for the solutions and assist our
                          clients to control and administer solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_text">
                      <span>2</span>
                      <div>
                        <h2>Design</h2>
                        <p>
                          To draft the design that is related to the research in
                          order to check the feasibility
                        </p>
                      </div>
                    </div>
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        src="../design.png"
                        height="50"
                        width="50"
                        alt="design"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../testing.png"
                        alt="testing"
                      />
                    </div>
                    <div className="ops_aboutUs_process_text">
                      <span>5</span>
                      <div>
                        <h2>Testing</h2>
                        <p>
                          In testing phase, we test every component make sure
                          that our solution fit the requirement
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_text">
                      <span>3</span>
                      <div>
                        <h2>Feasibility</h2>
                        <p>
                          After the completing feasibility test, a final design
                          is prepared by using smart tools
                        </p>
                      </div>
                    </div>
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../Feasibility.png"
                        alt="Feasibility"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../develop.png"
                        alt="develop"
                      />
                    </div>
                    <div className="ops_aboutUs_process_text">
                      <span>4</span>
                      <div>
                        <h2>Develop</h2>
                        <p>
                          Now our development team starts developing the
                          solutions according to the design
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;

import React from "react";
import Slider from "react-slick";

const Footer = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMargin: 2,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <footer
      className="text-center text-lg-start bg-body-tertiary  "
      style={{ backgroundColor: "#e4d06a", marginTop: "200px" }}
    >
      <div
        className="d-flex justify-content-center position-relative "
        style={{ bottom: "50px" }}
      >
        <div
          className="card bg-white text-center border india"
          style={{ width: "350px" }}
        >
          <img
            className=" position-relative border d-flex  rounded p-1"
            style={{ top: "-30px", left: "43%", color: "white" }}
            src="../footerlogo.png"
            alt="footerlogo"
            height="60px"
            width="60px"
          />
          <h1 className="fs-5 mt-0">
            <b>India Office</b>
          </h1>
          <p>
            <a
              className=""
              href="https://maps.app.goo.gl/cXRHWJDCuLbdHQtj9"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              title="location"
              target="_blank"
              rel="noreferrer"
            >
              516, 5th floor Pushti Heights, Gurukul road, memnagar, Ahmedabad
              Gujarat - 380052.
            </a>
          </p>
          <a
            href="tel:+91 9664053983"
            className="text-decoration-none text-dark"
            title="phone"
            target="_blank"
            rel="noreferrer"
          >
            <p className="mb-2">
              <i className="fa-solid fa-phone"></i> +91 9664053983
            </p>
          </a>
        </div>
      </div>

      <div className="aboutUs_we_design .aboutUs_we_design_top text-dark text-center">
        <h3>
          <span>
            <b>Builds Trust</b>
          </span>
          <br></br> with the advanced solutions and innovative search
        </h3>
        <p style={{ fontSize: "18px" }}>
          The developers with experience of more than 10+ years in the industry
          smartly lead the solutions. The reason is that we are reviewed by all
          the most trusted top firms and platforms.
        </p>
      </div>

      <section style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="sliderfootercontainer "
          style={{ overflow: "hidden", maxWidth: "100%", width: "65%" }}
        >
          <Slider {...settings} className="d-md-none d-block">
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <img
                src="../footercertificate.png"
                alt="footercertificate"
                style={{
                  width: "300px",
                  height: "200px",
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            </div>

            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://reputedfirms.com/company/70KIT1NWcPAnjYtWosUW/edit"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate3.png"
                  alt="footercertificate3"
                  style={{ width: "100%", padding: "25px", marginTop: "60px" }}
                />
              </a>
            </div>

            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://www.topdevelopers.co/companies/app-development"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate6.png"
                  alt="footercertificate6"
                  style={{
                    width: "300px",
                    height: "200px",
                    objectFit: "contain",
                    padding: "20px",
                  }}
                />
              </a>
            </div>

            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://app.theirstack.com/home"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate4.png"
                  alt="footercertificate4"
                  style={{
                    width: "250px",
                    height: "200px",
                    objectFit: "contain",
                    padding: "20px",
                  }}
                />
              </a>
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://www.topdevelopers.co/directory/web-development-companies"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate5.png"
                  alt="footercertificate5"
                  style={{
                    width: "300px",
                    height: "200px",
                    objectFit: "contain",
                    padding: "20px",
                  }}
                />
              </a>
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <img
                src="../Images/footercertificate7.png"
                alt="footercertificate7"
                style={{
                  width: "300px",
                  height: "200px",
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <img
                src="../Images/footercertificate8.png"
                alt="footercertificate8"
                style={{
                  width: "300px",
                  height: "200px",
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            </div>
          </Slider>
          <Slider {...settings} className="d-md-block d-none">
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <img
                src="../footercertificate.png"
                alt="footercertificate"
                style={{
                  width: "300px",
                  height: "200px",
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            </div>

            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://reputedfirms.com/company/70KIT1NWcPAnjYtWosUW/edit"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate3.png"
                  alt="footercertificate3"
                  style={{ width: "100%", padding: "25px", marginTop: "60px" }}
                />
              </a>
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://www.topdevelopers.co/companies/app-development"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate6.png"
                  alt="footercertificate6"
                  style={{
                    width: "300px",
                    height: "200px",
                    objectFit: "contain",
                    padding: "20px",
                  }}
                />
              </a>
            </div>

            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://app.theirstack.com/home"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate4.png"
                  alt="footercertificate4"
                  style={{
                    width: "300px",
                    height: "200px",
                    objectFit: "contain",
                    padding: "20px",
                  }}
                />
              </a>
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <a
                href="https://www.topdevelopers.co/directory/web-development-companies"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/footercertificate5.png"
                  alt="footercertificate5"
                  style={{
                    width: "300px",
                    height: "200px",
                    objectFit: "contain",
                    padding: "20px",
                  }}
                />
              </a>
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <img
                src="../Images/footercertificate7.png"
                alt="footercertificate7"
                style={{
                  width: "300px",
                  height: "200px",
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            </div>
            <div className="sliderfooter" style={{ margin: "0 auto" }}>
              <img
                src="../Images/footercertificate8.png"
                alt="footercertificate8"
                style={{
                  width: "300px",
                  height: "200px",
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            </div>
          </Slider>
        </div>
      </section>

      <section className="text-black p-3" style={{ zIndex: "1" }}>
        <div className="container text-center text-md-start ">
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-3 ">
              <h6 className="text-uppercase fw-bold mb-3 text-center">
                For Job Enquries
              </h6>
              <a
                href="tel:+91 9664053983"
                className="text-decoration-none text-dark  d-flex justify-content-center"
                title="phone"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-3 ">
                  <img src="../call.png" width="45px" alt="calllogo" /> +91
                  9664053983
                </p>
              </a>
              <a
                href="mailto:hr@mannix.in"
                className="text-decoration-none text-dark  d-flex justify-content-center"
                title="phone"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-3">
                  <img
                    src="../mail.png"
                    width="50px"
                    alt="maillogo"
                    style={{ marginLeft: "-20px" }}
                  />{" "}
                  hr@mannix.in
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 mb-3 text-center">
              <h6 className="text-uppercase fw-bold mb-3 ">Our Services</h6>
              <p>
                {" "}
                <a
                  href="/mobile-app-marekting-and-advertising-services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Digital Marketing
                </a>
              </p>
              <p>
                {" "}
                <a
                  href="/android-app-development-services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  App Development
                </a>
              </p>
              <p>
                <a
                  href="/wordpress-website-development-services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Web Development
                </a>
              </p>
              <p>
                {" "}
                <a
                  href="logo-design-services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Graphic Designing
                </a>
              </p>

              <p>
                {" "}
                <a
                  href="/crm-software-development-services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Software Development
                </a>
              </p>
            </div>

            <div className="col-md-6 col-lg-3 mb-3 text-center">
              <h6 className="text-uppercase fw-bold mb-3">Useful Links</h6>
              <a href="/career">
                <p
                  className="mb-2"
                  style={{
                    color: "black",
                  }}
                >
                  Career
                </p>
              </a>
              <a href="/contact">
                <p
                  className="mb-2"
                  style={{
                    color: "black",
                  }}
                >
                  Contact
                </p>
              </a>
              <a href="/portfolio">
                <p
                  className="mb-2"
                  style={{
                    color: "black",
                  }}
                >
                  Portfolio
                </p>
              </a>
              <a href="/about-us">
                <p
                  className="mb-2"
                  style={{
                    color: "black",
                  }}
                >
                  About Us
                </p>
              </a>
              <a href="/business">
                <p
                  className="mb-2"
                  style={{
                    color: "black",
                  }}
                >
                  Business-Growth
                </p>
              </a>
              <a href="/performance">
                <p
                  className="mb-2"
                  style={{
                    color: "black",
                  }}
                >
                  Performance Marketing
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 mb-3  ">
              <h6 className="text-uppercase fw-bold mb-3 text-center">
                Contact
              </h6>
              <a
                className="d-flex text-left d-none d-md-block mx-5"
                href="https://maps.app.goo.gl/cXRHWJDCuLbdHQtj9"
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                title="location"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-2">
                  <i className="fa-solid fa-location-dot me-3"></i>516, 5th
                  floor Pushti Heights, Gurukul road, memnagar, Ahmedabad
                  Gujarat - 380052.
                </p>
              </a>
              <a
                className="d-flex justify-content-center  d-md-none d-sm-block"
                href="https://maps.app.goo.gl/cXRHWJDCuLbdHQtj9"
                style={{ textDecoration: "none", color: "black" }}
                title="location"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-2">
                  <i className="fa-solid fa-location-dot me-3"></i>516, 5th
                  floor Pushti Heights, Gurukul road, memnagar, Ahmedabad
                  Gujarat - 380052.
                </p>
              </a>

              <a
                className="d-flex text-left d-none d-md-block mx-5"
                href="mailto:info@mannix.in"
                style={{ textDecoration: "none", color: "black" }}
                title="mailto"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-3">
                  <i className="fas fa-envelope me-2"></i>info@mannix.in
                </p>
              </a>
              <a
                className="d-flex justify-content-center d-md-none d-sm-block"
                href="mailto:info@mannix.in"
                style={{ textDecoration: "none", color: "black" }}
                title="mailto"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-3">
                  <i className="fas fa-envelope me-2"></i>info@mannix.in
                </p>
              </a>

              <a
                href="tel:+91 9664053983"
                className=" d-flex text-left d-none d-md-block mx-5"
                style={{ textDecoration: "none", color: "black" }}
                title="phone"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <i className="fas fa-phone me-1 mx-0"></i>+91 9664053983
                </p>
              </a>
              <a
                href="tel:+91 9664053983"
                className="text-decoration-none text-dark d-flex justify-content-center d-md-none d-sm-block"
                title="phone"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <i className="fas fa-phone me-1 mx-2"></i>+91 9664053983
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <img
          src="footervectorimg.png"
          alt="/footervectorimg"
          style={{ marginTop: "-50px", zIndex: "2" }}
          width="100%"
        />
      </section>

      <div
        className="text-center p-3 d-flex justify-content-between"
        style={{
          backgroundColor: "#000",
          color: "white",
          wordSpacing: "5PX",
        }}
      >
        <a
          href="//www.dmca.com/Protection/Status.aspx?ID=caea99f6-0117-4ff2-aa6c-f10f43eb4055"
          title="DMCA.com Protection Status"
          className="dmca-badge"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-03.png?ID=caea99f6-0117-4ff2-aa6c-f10f43eb4055"
            alt="DMCA.com Protection Status"
            style={{ width: "120px" }}
          />
        </a>{" "}
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
          {" "}
        </script>
        <p>
          {" "}
          Copyright © 2024 MANNIX INFOTECH SOLUTIONS PVT.LTD. All Rights
          Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
